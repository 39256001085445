import React from 'react';
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const PositiveBirth = () => {
    return (
      <section className="service-detail">
        <Helmet>
          <title>Positive Birth | IVF Superspecialist</title>
        </Helmet>

        {/* BANNER TEXT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="banner-text"
        >
          <div className="container">
            <div className="row justify-content-between gx-5">
              <div className="col-xl-5 col-sm-12">
                <h1>Positive Birth</h1>
                <p>
                  A positive birth is a deeply empowering and satisfying
                  experience where the birthing person feels supported,
                  respected, and in control of their choices throughout the
                  labor and delivery process. It involves a safe and nurturing
                  environment, effective communication, and a focus on the
                  physical, emotional, and psychological well-being of both.
                </p>
                <Button
                  className="consultation-btn"
                  onClick={() =>
                    window.Calendly.initPopupWidget({
                      url: "https://calendly.com/dr-kakkadvivek/appointment",
                    })
                  }
                >
                  Schedule appointment
                </Button>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="row h-100">
                  <div className="col-xl-6 col-6">
                    <img
                      src={require("../../assets/positive-birth-01.jpeg")}
                      className="service-banner"
                      alt="Positive Birth"
                    />
                  </div>
                  <div className="col-xl-6 col-6">
                    <img
                      src={require("../../assets/positive-birth-02.jpeg")}
                      className="service-banner"
                      alt="Positive Birthh"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* QUOTES */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="quote-box"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <div className="quote">
                  <h5>
                    The experience of giving birth after infertility treatment
                    can be particularly meaningful for couples, as it represents
                    a triumph over the obstacles they have faced. For many, it
                    is a moment of great joy and gratitude, and a testament to
                    the strength of their relationship and their commitment to
                    building a family.
                  </h5>
                  <div className="left-quote">
                    <img
                      src={require("../../assets/left-quote.png")}
                      alt="Left Quote"
                    />
                  </div>
                  <div className="right-quote">
                    <img
                      src={require("../../assets/right-quote.png")}
                      alt="Right Quote"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* HEADING */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="section-heading"
        >
          <div className="container">
            <h2>WHAT IS POSITIVE BIRTH ?</h2>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="description"
        >
          <div className="container">
            <p>
              A positive birth experience after infertility treatment can also
              have important psychological benefits for parents. Research has
              shown that women who have struggled with infertility and
              successfully given birth report higher levels of self-esteem,
              self-worth, and identity as a mother than women who conceive
              naturally. This increased sense of self can contribute to a more
              positive and fulfilling parenting experience overall.
            </p>
            <h5>Being able to do something that is so natural</h5>
            <p>
              For couples struggling with infertility, the journey to parenthood
              can be a long and difficult one. However, with the advancements in
              infertility treatments like in vitro fertilization (IVF), many
              couples are able to overcome their fertility challenges and
              achieve their dream of having a child. The experience of giving
              birth after infertility treatment can be particularly meaningful
              for couples, as it represents a triumph over the obstacles they
              have faced. For many, it is a moment of great joy and gratitude,
              and a testament to the strength of their relationship and their
              commitment to building a family.
            </p>
            <p>
              Research has shown that women who have undergone infertility
              treatment and given birth to a child are more likely to report
              high levels of satisfaction and fulfillment in their lives, and
              have a stronger sense of identity as a mother. They are also less
              likely to experience postpartum depression or anxiety.
            </p>
            <p>
              In addition to the emotional benefits, positive birth outcomes
              after infertility treatment are also encouraging from a medical
              perspective. Many studies have shown that babies born through IVF
              and other infertility treatments have similar health outcomes to
              those born through natural conception. Overall, the experience of
              giving birth after infertility treatment can be a transformative
              and empowering one for couples, offering hope and joy after a
              difficult and often trying journey.
            </p>
            <p>
              Giving birth after infertility treatment can be an incredibly
              positive and transformative experience for couples. The journey of
              infertility and treatment can be a long and challenging one, and
              the joy and satisfaction of finally bringing a child into the
              world can be especially meaningful. One of the key aspects of a
              positive birth experience after infertility treatment is the state
              of mind of the parents-to-be. Many couples who have struggled with
              infertility report feeling more prepared, patient, and resilient
              than other first-time parents. They have often faced multiple
              setbacks and obstacles in their quest to have a child, and have
              developed a sense of determination and strength that can carry
              over into the labor and delivery process.
            </p>
            <p>
              Additionally, couples who have undergone infertility treatment and
              successfully given birth are often deeply grateful for the
              opportunity to have a child, which can contribute to a sense of
              positivity and fulfillment during the birth experience. This
              gratitude can also be extended to the medical professionals who
              helped make the birth possible, creating a sense of teamwork and
              shared accomplishment.
            </p>
            <p>
              One of the key benefits of IVF is that it offers couples who
              struggle with infertility the opportunity to become parents. By
              using advanced reproductive technologies to overcome fertility
              challenges, IVF has helped many couples to conceive and give birth
              to healthy babies. This can create a sense of gratitude and
              appreciation for the medical professionals and technology that
              made the birth possible, contributing to a positive and empowered
              birth experience.
            </p>
            <p>
              IVF can also offer parents-to-be more control over the birth
              experience. For example, IVF allows couples to carefully plan the
              timing of the birth, which can be important for those who have
              undergone years of infertility treatment and want to make sure
              they are fully prepared for parenthood. Additionally, the use of
              pre-implantation genetic testing (PGT) can help to reduce the risk
              of genetic disorders, giving parents added peace of mind and
              reducing the chances of complications during the birth.
            </p>
          </div>
        </motion.div>
      </section>
    );
};

export default PositiveBirth;