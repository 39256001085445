import request from "./request";

// Contact Us API
export const contactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/contact-us`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};