import { Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const FertilityPreservation = () => {
    return (
      <section className="fertility-preservation">
        <Helmet>
          <title>Fertility Preservation | IVF Superspecialist</title>
        </Helmet>

        {/* BANNER TEXT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="banner-text"
        >
          <div className="container">
            <div className="row justify-content-between gx-5">
              <div className="col-xl-5 col-sm-12">
                <h5>FERTILITY PROCEDURES</h5>
                <h1>Fertility Preservation</h1>
                <p>
                  Fertility preservation involves freezing and storing
                  reproductive cells or tissues to protect the possibility of
                  having biological children in the future, often utilized by
                  individuals facing medical treatments or conditions that may
                  compromise their fertility.
                </p>
                <Button
                  className="consultation-btn"
                  onClick={() =>
                    window.Calendly.initPopupWidget({
                      url: "https://calendly.com/dr-kakkadvivek/appointment",
                    })
                  }
                >
                  Schedule appointment
                </Button>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="row h-100">
                  <div className="col-xl-6 col-6">
                    <img
                      src={require("../../../assets/services-banner/fertility-preservation-01.jpeg")}
                      className="service-banner"
                      alt="Laparoscopy and Hysteroscopy"
                    />
                  </div>
                  <div className="col-xl-6 col-6">
                    <img
                      src={require("../../../assets/services-banner/fertility-preservation-02.jpeg")}
                      className="service-banner"
                      alt="Laparoscopy and Hysteroscopy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* WHY NEED FERTILITY PRESERVATION AND WHEN SHOULD YOU CONSIDER FERTILITY PRESERVATION */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="when-why-fertility-preservation"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-sm-12">
                <div className="when-box">
                  <h2>Why may one need fertility preservation?</h2>
                  <p>
                    Fertility preservation can be important for people with
                    certain medical conditions.
                  </p>
                  <ul>
                    <li>
                      People with a history of exposure to toxic chemicals
                    </li>
                    <li>Women with endometriosis</li>
                    <li>Women with uterine fibroids</li>
                    <li>People about to receive cancer treatment</li>
                    <li>
                      People who are about to take autoimmune disease treatment
                    </li>
                    <li>
                      People suffering from a genetic disease that may affect
                      fertility in future
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="why-box">
                  <h2>When should one consider fertility preservation?</h2>
                  <p>One should consider fertility preservation if:</p>
                  <ul>
                    <li>
                      If you are about to receive cancer treatment like
                      chemotherapy or radiotherapy, you should consider freezing
                      your sperms, eggs, or embryos. Some of the treatment
                      modalities of cancer can cause permanent infertility.
                    </li>
                    <li>
                      If you are in a job with high chances of physical injury
                      (defense and police services), you may choose to freeze
                      your eggs, sperm, or embryos.
                    </li>
                    <li>
                      You should also consider fertility preservation techniques
                      if you feel that you are still not ready for family and
                      age is not on your side.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </motion.section>

        {/* MOST COMMON FERTILITY PRESERVATION */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="common-fertility-preservation"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h2>
                  What are the most common fertility preservation measures?
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-sm-12">
                <div className="preservation-box">
                  <h5>Embryo Cryopreservation</h5>
                  <p>
                    Embryo cryopreservation, also known as embryo freezing, is a
                    highly effective fertility preservation technique frequently
                    utilized by females. The procedure involves extracting eggs
                    from the ovaries, which are then fertilized with sperm
                    through in vitro fertilization.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="preservation-box">
                  <h5>Ovarian Transposition</h5>
                  <p>
                    Ovarian transposition, also known as ovarian suspension or
                    ovarian relocation, is a surgical procedure in which the
                    ovaries are moved or repositioned to a different location
                    within the pelvis or abdomen.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="preservation-box">
                  <h5>Oocyte Cryopreservation</h5>
                  <p>
                    Oocyte cryopreservation, also known as egg freezing, is a
                    fertility preservation technique that involves the freezing
                    and storage of unfertilized eggs (oocytes) for future use.
                    It allows women to preserve their fertility by harvesting
                    eggs during their reproductive years and freezing them for
                    later use when they are ready to conceive.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="preservation-box">
                  <h5>Gonadal Shielding</h5>
                  <p>
                    Gonadal shielding refers to use of protective barriers or
                    shields to minimize radiation exposure to the reproductive
                    organs, specifically the testes in males and the ovaries in
                    females. It is employed in medical imaging procedures, such
                    as X-rays or CT scans, where the radiation field may
                    encompass or be in close proximity to the pelvic region.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.section>

        {/* STEPS TO FOLLOW */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="holistic-care"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h2>Points to remember for successful pregnancy</h2>
              </div>
            </div>
            <div className="row mt-4 justify-content-center">
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/healthy-diet.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Eat a Healthy Diet</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/exercise.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Exercise Regularly</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/sleep.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Get Enough Sleep</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/doctor-bag.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Follow Medical Advice</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/stress.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Manage Stress</h5>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    );
};

export default FertilityPreservation;