import { Link } from "react-router-dom";

const BlogBox = (props) => {
    return (
      <Link to={props.blogLink} className="blog-box">
        <img
          src={props.blogImg}
          className="blog-img"
          alt={props.blogTitle}
        />
        <h5>{props.blogTitle}</h5>
        <p>{props.blogDesc}</p>
        <Link to={props.blogLink} className="learn-more-link">
          Learn more
        </Link>
      </Link>
    );
};

export default BlogBox;