import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* ICONS IMPORTS */
import GlobeIcon from '@iconscout/react-unicons/icons/uil-globe';
import ValuesIcon from '@iconscout/react-unicons/icons/uil-heart';
import SmileIcon from '@iconscout/react-unicons/icons/uil-smile';
import TechnologyIcon from '@iconscout/react-unicons/icons/uil-window';

const AboutUs = () => {
    return (
      <section className="about-us">
        <Helmet>
          <title>About Dr Vivek Kakkad | IVF Superspecialist</title>
        </Helmet>

        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>About Dr. Vivek Kakkad</h1>
                <h5>
                  Passionate about empowering women throughout their pregnancy journey.
                </h5>
              </div>
            </div>
          </div>
        </motion.div>

        {/* SERVICES */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="services"
        >
          <div className="container">
            <div className="grey-box">
              <div className="row">
                <div className="col-xl-3 col-sm-12">
                  <Link
                    to="/ivf-and-conception-care"
                    style={{ color: "#323232", textDecoration: "none" }}
                  >
                    <div className="services-box">
                      <img
                        src={require("../../assets/icons/about-01.PNG")}
                        alt="Interventional Gyynaecology and Surgey"
                      />
                      <h5>IVF and Conception Care</h5>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-sm-12">
                  <Link
                    to="/male-and-female-infertility"
                    style={{ color: "#323232", textDecoration: "none" }}
                  >
                    <div className="services-box">
                      <img
                        src={require("../../assets/icons/about-02.PNG")}
                        alt="Interventional Gyynaecology and Surgey"
                      />
                      <h5>Male and Female Infertility</h5>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-sm-12">
                  <Link
                    to="/fertility-preservation"
                    style={{ textDecoration: "none", color: "#323232" }}
                  >
                    <div className="services-box">
                      <img
                        src={require("../../assets/icons/about-03.PNG")}
                        alt="Interventional Gyynaecology and Surgey"
                      />
                      <h5>Fertility Preservation</h5>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-sm-12">
                  <Link
                    to="/laparoscopy-and-hysteroscopy"
                    style={{ textDecoration: "none", color: "#323232" }}
                  >
                    <div className="services-box">
                      <img
                        src={require("../../assets/icons/about-04.PNG")}
                        alt="Interventional Gyynaecology and Surgey"
                      />
                      <h5>Laparoscopy and Hysteroscopy</h5>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* ABOUT */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="about-details"
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-6 col-sm-12">
                <h2>Comprehensive range of capabilities</h2>
                <h5>Delivering world class gynaecology care</h5>
                <p>
                  Welcome to the website of Dr. Vivek Pravinchandra Kakkad, a{" "}
                  <b>Reproductive medicine and IVF specialist</b> with a passion
                  for helping couples achieve their dream of starting a family.
                  With extensive experience and expertise in the field of
                  reproductive medicine, Dr. Kakkad is committed to providing
                  personalized and compassionate care to each patient.
                </p>
                <p>
                  Dr. Vivek Kakkad completed his medical degree from Medical
                  college Baroda, Vadodara and went on to pursue his
                  post-graduation in{" "}
                  <b>
                    Obstetrics and Gynecology from renowned All India Institute
                    Of Medical Sciences (AIIMS)
                  </b>
                  , New Delhi. Dr. Vivek pursued his{" "}
                  <b>
                    M.Ch. in Reproductive Medicine and Surgery from Sri
                    Ramachandra Institute of Higher Education and Research,
                    Chennai
                  </b>{" "}
                  and became the very first superspecialist IVF consultant
                  practicing in Gujarat. Dr. Kakkad acquired professional
                  training in <b>Andrology from the University of Miami, USA</b>
                  , further enhancing his knowledge and skills in this
                  specialized field. Throughout his career, Dr. Kakkad has been
                  committed to staying at the forefront of the latest advances
                  in fertility treatment and technology.
                </p>
                <p className="mb-0">
                  As a specialist in IVF and infertility treatment, Dr. Vivek
                  Kakkad has helped thousands of couples fulfill their dream of
                  starting a family. His areas of expertis e include{" "}
                  <b>
                    in vitro fertilization (IVF), intrauterine insemination
                    (IUI), fertility preservation, and other advanced assisted
                    reproductive technologies (ART)
                  </b>
                  . With a deep understanding of the unique challenges and
                  cultural context of infertility, Dr. Kakkad provides
                  personalized and culturally sensitive care to each patient.
                  Whether you are just beginning to explore fertility treatment
                  options or have been struggling with infertility for some
                  time, we invite you to learn more about how Dr. Vivek Kakkad
                  and our team can help you on your path to parenthood.
                </p>
              </div>
              <div className="col-xl-5 col-sm-12">
                <img
                  src={require("../../assets/about-personal-01.webp")}
                  className="banner-about"
                  alt="About Dr Vivek Kakkad"
                />
                <img
                  src={require("../../assets/about-personal-02.webp")}
                  className="banner-about"
                  style={{ marginTop: "20px" }}
                  alt="About Dr Vivek Kakkad"
                />
              </div>
            </div>
          </div>
        </motion.section>

        {/* VISION AND VALUES */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="vision-values"
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-sm-12">
                <img
                  src={require("../../assets/about-image-02.webp")}
                  className="banner-img"
                  alt="Vision and Values"
                />
              </div>
              <div className="col-xl-6 col-sm-12">
                <h5>Empathy. Excellence. Empowerment.</h5>
                <h2>Empowering women for positive birth</h2>
                <div className="row details">
                  <div className="col-xl-6 col-sm-12">
                    <div className="box">
                      <GlobeIcon size="35" color="#02C9B8" />
                      <h5>Vision</h5>
                      <p>
                        To provide personalized care and advanced fertility
                        treatments for couples struggling with infertility,
                        ensuring their best chances of achieving their dream of
                        parenthood.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="box">
                      <ValuesIcon size="35" color="#02C9B8" />
                      <h5>Values</h5>
                      <p>
                        Understanding the emotional and physical challenges of
                        infertility, and providing support throughout the
                        journey and deliver the best possible outcomes for their
                        patients.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="box">
                      <SmileIcon size="35" color="#02C9B8" />
                      <h5>Providers</h5>
                      <p>
                        Provide patient-centered healthcare with excellence in
                        quality, service, and access a believe broad range of
                        human needs is the best way to improve a person’s
                        health.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="box">
                      <TechnologyIcon size="35" color="#02C9B8" />
                      <h5>Technology</h5>
                      <p>
                        Partnerships with education, government, and other
                        community organizations will care multiply the resources
                        for effectiveness of our work or everyday care or
                        life-changing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.section>
      </section>
    );
};

export default AboutUs;