import React from "react";
import { Button } from "react-bootstrap";

const ReproductiveEndocrinology = () => {
  return (
    <section className="service-detail">

      {/* BANNER */}
      <div className="page-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h1>Reproductive Endocrinology</h1>
              <Button className="appointment-btn">MAKE AN APPOINTMENT</Button>
            </div>
          </div>
        </div>
      </div>

      {/* HEADING */}
      <div className="section-heading">
        <div className="container">
          <h2>WHAT IS REPRODUCTIVE ENDOCRINOLOGY ?</h2>
        </div>
      </div>

      {/* DESCRIPTION */}
      <div className="description">
        <div className="container">
          <p>
            Reproductive endocrinology is a specialized field of medicine that
            focuses on the hormonal and reproductive systems of both men and
            women. As a well trained professional with extensive experience and
            expertise in the diagnosis and treatment of a wide range of
            reproductive health conditions, including infertility, polycystic
            ovary syndrome (PCOS), endometriosis, menstrual disorders, and
            hormonal imbalances.
          </p>
          <p>
            At our clinic, we take a comprehensive approach to reproductive
            endocrinology, utilizing the latest technologies and treatments to
            provide the best possible care for our patients. We understand that
            every patient is unique, and we take the time to develop
            individualized treatment plans that are tailored to each patient's
            specific needs and goals.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ReproductiveEndocrinology;
