import { Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const LaparoscopyAndHysteroscopy = () => {
    return (
      <section className="laparoscopy">
        <Helmet>
          <title>Laparoscopy and Hysteroscopy | IVF Superspecialist</title>
        </Helmet>

        {/* BANNER TEXT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="banner-text"
        >
          <div className="container">
            <div className="row justify-content-between gx-5">
              <div className="col-xl-5 col-sm-12">
                <h5>SURGICAL PROCEDURES</h5>
                <h1>Laparoscopy and Hysteroscopy</h1>
                <p>
                  Laparoscopy and Hysteroscopy are both types of minimally
                  invasive surgical procedures that doctors use to diagnose and
                  treat certain causes of female infertility. These procedures
                  are particularly useful in cases where endometriosis, uterine
                  fibroids, endometrial polyps, uterine anomalies, or pelvic
                  infection are suspected.
                </p>
                <Button
                  className="consultation-btn"
                  onClick={() =>
                    window.Calendly.initPopupWidget({
                      url: "https://calendly.com/dr-kakkadvivek/appointment",
                    })
                  }
                >
                  Schedule appointment
                </Button>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="row h-100">
                  <div className="col-xl-6 col-6">
                    <img
                      src={require("../../../assets/services-banner/laparoscopy-banner-01.jpeg")}
                      className="service-banner"
                      alt="Laparoscopy and Hysteroscopy"
                    />
                  </div>
                  <div className="col-xl-6 col-6">
                    <img
                      src={require("../../../assets/services-banner/laparoscopy-banner-02.jpeg")}
                      className="service-banner"
                      alt="Laparoscopy and Hysteroscopy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* WHAT IS LAPAROSCOPY */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="what-is-laparoscopy"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h2>What is Laparoscopy?</h2>
              </div>
              <div className="col-xl-12 col-sm-12">
                <p>
                  Laparoscopy, also known as endoscopy or pelviscopy, is a
                  minimally invasive surgical procedure that involves inserting
                  a small telescope through a small incision below the navel to
                  visualize the pelvic organs of women who are experiencing
                  infertility. This procedure enables doctors to diagnose
                  various conditions that can cause infertility, such as damaged
                  tubes, endometriosis, adhesions, and tuberculosis. Laparoscopy
                  is an important tool for assessing the reproductive health of
                  women and can help doctors determine the best course of
                  treatment to improve their chances of conception.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h3>Reasons for Laparoscopy</h3>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Dysmenorrhea (painful menses)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Dyspareunia (painful sexual intercourse)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Endometriosis treatment</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Fimbrioplasty (repair of fallopian tubes)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Myomectomy (remove fibroids)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Ovarian cystectomy(remove cysts)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Ovarian drilling</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Pelvic adhesions treatment</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h3>How is it performed?</h3>
                <ol>
                  <li>
                    <b>Incision and Insertion:</b> A small incision, usually
                    below the navel, is made to create a port for the
                    laparoscope. Carbon dioxide gas is then pumped into the
                    abdomen to create space for better visualization.
                  </li>
                  <li>
                    <b>Insertion of the laparoscope:</b> The laparoscope, a
                    thin, long tube with a light and camera at its tip, is
                    inserted through the incision. It allows the surgeon to view
                    the pelvic organs on a monitor in real-time.
                  </li>
                  <li>
                    <b>Exploration and diagnosis:</b> The laparoscope is used to
                    carefully examine the pelvic organs, including the uterus,
                    ovaries, fallopian tubes, and surrounding tissues. The
                    surgeon can identify any abnormalities that may be causing
                    infertility or other issues.
                  </li>
                  <li>
                    <b>Additional incisions and instruments:</b> If necessary,
                    additional small incisions may be made to insert specialized
                    surgical instruments. These instruments allow the surgeon to
                    perform various procedures such as removing cysts, repairing
                    damaged tissues, or addressing any other identified issues.
                  </li>
                  <li>
                    <b>Closure:</b> Once the procedure is completed, the
                    laparoscope and instruments are removed, and the gas is
                    released from the abdomen. The incisions are typically
                    closed with sutures or adhesive strips, and sterile
                    dressings are applied.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </motion.div>

        {/* WHAT IS HYSTEROSCOPY */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="what-is-hysteroscopy"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h2>What is Hysteroscopy?</h2>
              </div>
              <div className="col-xl-12 col-sm-12">
                <p>
                  Hysteroscopy is a commonly recommended procedure for
                  investigating the underlying causes of infertility,
                  miscarriage, and abnormal uterine bleeding. Typically, it is
                  preceded by other imaging studies like ultrasound. By
                  utilizing a hysteroscope, doctors can examine the uterine
                  cavity to identify any abnormalities such as fibroids, polyps,
                  areas of scarring, or congenital malformations. If necessary,
                  surgical interventions can be performed during the
                  hysteroscopy to correct specific abnormalities.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h3>Reasons for Hysteroscopy</h3>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Abnormal bleeding</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Abnormal hysterosalpingogram</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Abnormal sonogram</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Abnormal sonohysterogram</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Removal of uterine septum</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Removal of scar tissue (adhesions)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Myomectomy (remove fibroids)</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12">
                <div className="reason-box">
                  <p>Polypectomy (remove polyp)</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <h3>How is it performed?</h3>
                <ol>
                  <li>
                    <b>Dilating the cervix:</b> To allow access to the uterus,
                    the cervix is dilated using specialized dilators or
                    medication. This helps create a wider opening for the
                    hysteroscope to pass through.
                  </li>
                  <li>
                    <b>Insertion of the hysteroscope: </b> A hysteroscope, a
                    thin, lighted tube with a camera at its end, is inserted
                    through the vagina and into the cervix. It is carefully
                    guided into the uterus, allowing the doctor to visualize the
                    uterine cavity on a monitor.
                  </li>
                  <li>
                    <b>Examination and diagnosis:</b> The hysteroscope enables
                    the doctor to examine the uterine lining and identify any
                    abnormalities such as fibroids, polyps, scarring, or
                    congenital malformations. If necessary, small surgical
                    instruments can be inserted through the hysteroscope to
                    perform procedures like polyp removal or tissue biopsy.
                  </li>
                  <li>
                    <b>Fluid or gas infusion:</b> Throughout the procedure, a
                    sterile fluid or gas may be infused into the uterus to
                    expand the cavity, allowing for better visualization.
                  </li>
                  <li>
                    <b>Removal and closure:</b> Once the procedure is complete,
                    the hysteroscope is removed from the uterus, and any
                    instruments used are also taken out. The cervix may be
                    gently packed with absorbent material to minimize bleeding.
                    The anesthesia is gradually stopped, and the patient is
                    moved to a recovery area.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </motion.div>

        {/* STEPS TO FOLLOW */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="holistic-care"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h2>Points to remember for successful pregnancy</h2>
              </div>
            </div>
            <div className="row mt-4 justify-content-center">
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/healthy-diet.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Eat a Healthy Diet</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/exercise.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Exercise Regularly</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/sleep.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Get Enough Sleep</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/doctor-bag.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Follow Medical Advice</h5>
                </div>
              </div>
              <div className="col-xl-2 col-6">
                <div className="tips-box">
                  <div className="steps-icon-box">
                    <img
                      src={require("../../../assets/icons/stress.png")}
                      alt="Steps Icon"
                    />
                  </div>
                  <h5>Manage Stress</h5>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    );
};

export default LaparoscopyAndHysteroscopy;