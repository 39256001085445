/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";

/* EXTERNAL IMPORTS */
import TelephoneIcon from '../../assets/icons/telephone.png';

const Header = () => {
    return (
      <header className="header">
        {/* TOP HEADER */}
        <div className="top-header">
          <div className="container">
            <div className="top-header-links">
              <div className="left-section">
                <a
                  href="https://www.facebook.com/profile.php?id=100092650021285"
                  target="_blank"
                  rel="noreferrer"
                  className="link-item pl-0"
                >
                  Facebook
                </a>
                <a
                  href="https://www.instagram.com/gujfertilitydoc/"
                  target="_blank"
                  rel="noreferrer"
                  className="link-item"
                >
                  Instagram
                </a>
                <a
                  href="https://twitter.com/drkakkadvivek1"
                  target="_blank"
                  rel="noreferrer"
                  className="link-item"
                >
                  Twitter
                </a>
                <a
                  href="https://www.linkedin.com/in/vivek-kakkad-b1108a90/"
                  target="_blank"
                  rel="noreferrer"
                  className="link-item"
                >
                  Linkedin
                </a>
              </div>
              <div className="right-section">
                <Link to={'tel:+919898283962'} className="telephone pl-0">
                  <img src={TelephoneIcon} alt="Contact Dr. Vivek Kakkad" />
                  +91 9898283962
                </Link>
                <a
                  href="#"
                  onClick={() =>
                    window.Calendly.initPopupWidget({
                      url: "https://calendly.com/dr-kakkadvivek/appointment",
                    })
                  }
                  className="link-item pr-0"
                >
                  Book Appointment
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* HEADER */}
        <div className="header-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              {/* LOGO */}
              <Link to="/">
                <img
                  src={require("../../assets/website-logo.png")}
                  className="logo"
                  alt="Dr Vivek Kakkad | IVF Superspecialist"
                />
              </Link>

              {/* NAVIGATION BAR */}
              <div className="header-right">
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="menu-expand" />
                  <Navbar.Offcanvas id="menu-expand">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id="offcanvas-expand-lg">
                        <Link to="/">
                          <img
                            src={require("../../assets/website-logo.png")}
                            className="logo"
                            style={{ width: "250px", height: "auto" }}
                            alt="Dr Vivek Kakkad | IVF Superspecialist"
                          />
                        </Link>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="main-menu">
                        <Nav.Link href="/" className="menu-link">
                          Home
                        </Nav.Link>
                        <Nav.Link href="/about-us" className="menu-link">
                          About Us
                        </Nav.Link>
                        <NavDropdown
                          title="Services"
                          className="menu-link-dropdown"
                        >
                          <NavDropdown.Item href="/ivf-and-conception-care">
                            IVF &amp; Conception Care
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/male-and-female-infertility">
                            Male &amp; Female Infertility
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/fertility-preservation">
                            Fertility Preservation
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/laparoscopy-and-hysteroscopy">
                            Laparoscopy and Hysteroscopy
                          </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/positive-birth" className="menu-link">
                          Positive Birth
                        </Nav.Link>
                        <Nav.Link
                          href="/publications-and-blogs"
                          className="menu-link"
                        >
                          Publications and Blogs
                        </Nav.Link>
                        <Nav.Link href="/press-and-media" className="menu-link">
                          Press and Media
                        </Nav.Link>
                        <Nav.Link href="/contact-us" className="menu-link pr-0">
                          Contact Us
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
};

export default Header;