import { Button, Card } from "react-bootstrap";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const TreatmentCard = (props) => {
  return (
    <Card className="treatment-card">
      <Card.Body className="treatment-cardbody">
        <div className="step-circle">{props.step}</div>
        <h5>{props.name}</h5>
        <p>{props.desc}</p>
        <p className="second-text">{props.secondDesc}</p>
      </Card.Body>
    </Card>
  );
};

const IVFPage = () => {
  return (
    <section className="new-services-page">
      <Helmet>
        <title>IVF, IUI, ICSI and more | IVF Superspecialist</title>
      </Helmet>

      {/* BANNER TEXT */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="banner-text ivf-banner"
      >
        <div className="container">
          <div className="row justify-content-between gx-5">
            <div className="col-xl-5 col-sm-12">
              <h5>FERTILITY PROCEDURES</h5>
              <h1>IVF, IUI, ICSI and more.</h1>
              <p>
                Conceiving a child may not be a straightforward process for
                everyone. Statistics suggest that around 1 in 8 couples face
                challenges when trying to achieve or maintain a pregnancy. If
                you're experiencing difficulties, a Fertility Consultation can
                help you understand your options and create a personalized plan.
                You can take the first step towards parenthood without worrying
                about the cost
              </p>
              <Button
                className="consultation-btn"
                onClick={() =>
                  window.Calendly.initPopupWidget({
                    url: "https://calendly.com/dr-kakkadvivek/appointment",
                  })
                }
              >
                Schedule appointment
              </Button>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="row h-100">
                <div className="col-xl-6 col-6">
                  <img
                    src={require("../../../assets/services-banner/ivf-banner-01.jpg")}
                    className="service-banner"
                    alt="IVF, IUI, ICSI and more"
                  />
                </div>
                <div className="col-xl-6 col-6">
                  <img
                    src={require("../../../assets/services-banner/ivf-banner-02.jpeg")}
                    className="service-banner"
                    alt="IVF, IUI, ICSI and more"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PATHS TO CONCEPTION */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="paths-to-conception"
      >
        <div className="container">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="row justify-content-center"
          >
            <div className="col-xl-8 col-sm-12">
              <h2>Paths to Conception</h2>
              <p>
                Many couples may face challenges when trying to conceive, and
                the reality is that getting pregnant isn't always easy. In fact,
                statistics show that approximately 1 in 8 couples have
                difficulty getting or staying pregnant.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="row justify-content-center mt-4"
          >
            <div className="col-xl-10 col-sm-12">
              <div className="paths-box">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-5 col-sm-12">
                    <img
                      src={require("../../../assets/services-banner/timed-intercourse.jpeg")}
                      alt="Timed Intercourse"
                    />
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <h3>Timed Intercourse</h3>
                    <p>
                      Did you know that conception is only possible during a
                      woman's fertile window, which typically lasts for about 5
                      days every menstrual cycle? Our approach to helping you
                      conceive naturally involves carefully monitoring your
                      menstrual cycle through ultrasound and blood work, or by
                      using a home ovulation predictor kit, to accurately
                      identify your fertile window. By timing and engaging in
                      sexual intercourse during this window, we can help you
                      achieve your goal of starting or growing your family.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-sm-12">
              <div className="paths-box bg-white">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-6 col-sm-12">
                    <h3>Oral Medications like Clomid</h3>
                    <p>
                      Clomid is a medication that is used to stimulate ovulation
                      in women who are experiencing fertility issues. It works
                      by tricking the body into thinking that estrogen levels
                      are lower than they actually are, which in turn increases
                      the levels of follicle-stimulating hormone (FSH) and
                      luteinizing hormone (LH) in the bloodstream. These
                      hormones play a crucial role in stimulating the ovary to
                      produce one or more egg follicles that will mature and be
                      released during ovulation. By raising LH levels, Clomid
                      helps to trigger ovulation. To learn more about how Clomid
                      can help you with your fertility goals, I encourage you to
                      schedule a consultation call.
                    </p>
                  </div>
                  <div className="col-xl-5 col-sm-12">
                    <img
                      src={require("../../../assets/services-banner/oral-medications.jpeg")}
                      alt="Timed Intercourse"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-sm-12">
              <div className="paths-box">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-5 col-sm-12">
                    <img
                      src={require("../../../assets/services-banner/iui.jpeg")}
                      alt="IUI"
                    />
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <h3>IUI</h3>
                    <p>
                      Intrauterine insemination (IUI) is a fertility treatment
                      that can help increase the chances of conception by
                      placing washed and concentrated sperm directly into the
                      uterus during the time of ovulation. This procedure is
                      timed to coincide with the release of one or more mature
                      eggs from the ovary. By placing the sperm directly into
                      the uterus, this increases the likelihood of fertilization
                      and may help overcome certain barriers to conception. If
                      you are interested in learning more about IUI I recommend
                      scheduling a consultation call.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-sm-12">
              <div className="paths-box bg-white">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-6 col-sm-12">
                    <h3>IVF</h3>
                    <p>
                      In-Vitro Fertilization (IVF) is a comprehensive fertility
                      treatment that involves a combination of medications and
                      surgical procedures to help sperm fertilize an egg and
                      facilitate implantation of the fertilized embryo into the
                      uterus. This process typically involves several steps,
                      including ovarian stimulation to produce multiple mature
                      eggs, egg retrieval, fertilization of the eggs in a
                      laboratory, and transfer of the resulting embryo(s) into
                      the uterus. After the embryo transfer, it takes around two
                      weeks to determine whether the procedure has resulted in a
                      pregnancy.
                    </p>
                  </div>
                  <div className="col-xl-5 col-sm-12">
                    <img
                      src={require("../../../assets/services-banner/ivf.jpeg")}
                      alt="Timed Intercourse"
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>

      {/* IVF WITH VIVEK KAKKAD */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="ivf-with-vivek-kakkad"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h2>Fertility Treatments with Dr Vivek Kakkad</h2>
            </div>
          </div>
          <div className="row mt-2 gy-4">
            <div className="col-xl-4 col-sm-12">
              <TreatmentCard
                step="01"
                name="Fertility Consultation"
                desc="The conception consultation serves as the initial stage, which includes a blood test for fertility hormones, medical history review, an ultrasound, and a personalized treatment plan."
              />
            </div>
            <div className="col-xl-4 col-sm-12">
              <TreatmentCard
                step="02"
                name="Personalized Plan"
                desc="Upon receiving your consultation results, a certified repro-ductive endocrinologist will analyze the results and recom-mend a personalized plan suitable for you."
              />
            </div>
            <div className="col-xl-4 col-sm-12">
              <TreatmentCard
                step="03"
                name="Stimulation Process (Day 1-13)"
                desc="As part of the IVF process, you will self-administer small hormone injections at home to stimulate your ovaries, promoting the growth of more eggs."
              />
            </div>
            <div className="col-xl-4 col-sm-12">
              <TreatmentCard
                step="04"
                name="Egg Retrieval & Sperm Collection (Day 14)"
                desc="The egg retrieval procedure lasts about 10-15 minutes, where you will receive sedation to ensure your comfort. A physician will then retrieve your eggs, and you may experi-ence some soreness following the procedure. "
              />
            </div>
            <div className="col-xl-4 col-sm-12">
              <TreatmentCard
                step="05"
                name="Embryo Transfer (Day 19)"
                desc="Approximately 3-5 days after the egg retrieval, the highest quality embryo(s) will be selected for transfer into your uterus. The transfer procedure is usually completed without sedation and only takes a few minutes."
              />
            </div>
            <div className="col-xl-4 col-sm-12">
              <TreatmentCard
                step="06"
                name="Pregnancy Test (Day 28)"
                desc="Approximately 9-11 days following the embryo transfer, you will undergo a pregnancy test to determine whether the embryo has successfully implanted and is developing into a foetus."
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* SCHEDULE CONSULTATION */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="schedule-consultation"
      >
        <div className="container">
          <div className="row align-items-center gx-5">
            <div className="col-xl-3 col-sm-12">
              <h2>Get in Touch</h2>
              <h5>The family you've dreamed of, now within reach.</h5>
            </div>
            <div className="col-xl-9 col-sm-12">
              <div className="row">
                <div className="col-xl-4 col-sm-12">
                  <Card className="appointment-card">
                    <Card.Body className="appointment-cardbody">
                      <h4>Fertility Consultation</h4>
                      <p>
                        I will provide you with a comprehensive consultation and
                        diagnostic work-up to help you understand your fertility
                        options and create a conception plan.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <Card className="appointment-card">
                    <Card.Body className="appointment-cardbody">
                      <h4>IUI</h4>
                      <p>
                        Intrauterine Insemination (IUI) involves directly
                        placing sperm inside the uterus. This includes ovarian
                        stimulation, monitoring, and the IUI procedure.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <Card className="appointment-card">
                    <Card.Body className="appointment-cardbody">
                      <h4>IVF</h4>
                      <p>
                        In-vitro Fertilization (IVF) is about a month-long cycle
                        where your ovaries are stimulated, eggs are retrieved,
                        fertilized, and transferred.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* STEPS TO FOLLOW */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="holistic-care"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h2>Points to remember for successful pregnancy</h2>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/healthy-diet.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Eat a Healthy Diet</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/exercise.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Exercise Regularly</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/sleep.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Get Enough Sleep</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/doctor-bag.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Follow Medical Advice</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/stress.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Manage Stress</h5>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default IVFPage;
