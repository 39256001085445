/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from 'react-bootstrap';

/* LAYOUT & COMPONENTS */
import Layout from '../layout/index';

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

/* PAGES */
import Homepage from "../pages/home";
import AboutUs from "../pages/about-us";
import PositiveBirth from "../pages/positive-birth";
import PublicationsAndBlogs from "../pages/publications-and-blogs/index";
import ContactUs from "../pages/contact-us";

/* SERVICES */
import IVFPage from "../pages/services/in-vitro-fertilization";
import MaleAndFemaleInfertility from "../pages/services/male-and-female-infertility/new-index";
import ReproductiveEndocrinology from "../pages/services/reproductive-endocrinology";
import LaparoscopyAndHysteroscopy from "../pages/services/laparoscopy-and-hysteroscopy";
import FertilityPreservation from "../pages/services/fertility-preservation";

/* BLOGS */
import FrozenEmbryoTransfer from "../pages/publications-and-blogs/blogs/frozen-embryo-transfer";
import CommonOvulationSigns from "../pages/publications-and-blogs/blogs/common-ovulation-signs";
import FertilityPreservationBlog from "../pages/publications-and-blogs/blogs/fertility-preservation";
import ConceivingWithIVF from "../pages/publications-and-blogs/blogs/conceiving-with-ivf";
import SymptomsOfMaleInfertility from "../pages/publications-and-blogs/blogs/signs-of-male-infertility";
import IVFProcedurePainful from "../pages/publications-and-blogs/blogs/ivf-procedure-painful";
import SuccessfulEmbryoTransfer from "../pages/publications-and-blogs/blogs/successful-embryo-transfer";
import SecondaryInfertility from "../pages/publications-and-blogs/blogs/secondary-infertility";
import Top10ReasonsToSeeGynaecologist from "../pages/publications-and-blogs/blogs/10-reasons-to-see-gynaecologist";
import Top10ReasonsToSeeFertilitySpecialist from "../pages/publications-and-blogs/blogs/10-reasons-to-see-your-fertility-specialist";
import PressAndMedia from "../pages/press-media";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebsiteRoutes = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <div className="app">
        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/positive-birth" element={<PositiveBirth />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route
                path="/publications-and-blogs"
                element={<PublicationsAndBlogs />}
              />
              <Route
                path="/male-and-female-infertility"
                element={<MaleAndFemaleInfertility />}
              />
              <Route path="/ivf-and-conception-care" element={<IVFPage />} />
              <Route
                path="/reproductive-endocrinology"
                element={<ReproductiveEndocrinology />}
              />
              <Route
                path="/laparoscopy-and-hysteroscopy"
                element={<LaparoscopyAndHysteroscopy />}
              />
              <Route
                path="/fertility-preservation"
                element={<FertilityPreservation />}
              />
              <Route 
                path="/press-and-media"
                element={<PressAndMedia />}
              />

              {/* BLOGS */}
              <Route
                path="/frozen-embryo-transfer-procedure"
                element={<FrozenEmbryoTransfer />}
              />
              <Route
                path="/common-ovulation-signs"
                element={<CommonOvulationSigns />}
              />
              <Route
                path="/fertility-preservation-in-india"
                element={<FertilityPreservationBlog />}
              />
              <Route
                path="/conceiving-with-ivf"
                element={<ConceivingWithIVF />}
              />
              <Route
                path="/symptoms-and-signs-of-male-infertility"
                element={<SymptomsOfMaleInfertility />}
              />
              <Route
                path="/is-ivf-procedure-painful"
                element={<IVFProcedurePainful />}
              />
              <Route
                path="/successful-embryo-transfer"
                element={<SuccessfulEmbryoTransfer />}
              />
              <Route
                path="/secondary-infertility"
                element={<SecondaryInfertility />}
              />
              <Route
                path="/top-10-reasons-to-see-your-gynaecologist"
                element={<Top10ReasonsToSeeGynaecologist />}
              />
              <Route
                path="/top-10-reasons-to-see-your-fertility-specialist"
                element={<Top10ReasonsToSeeFertilitySpecialist />}
              />
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default WebsiteRoutes;