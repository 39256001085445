import toast, { Toaster } from "react-hot-toast";
import { Button } from "react-bootstrap";

import * as yup from "yup";
import { useFormik } from "formik";
import { motion } from "framer-motion";

/* EXTERNAL IMPORTS */
import TelephoneIcon from "../../assets/icons/telephone.png";
import EmailIcon from "../../assets/icons/email.png";

import FacebookIcon from "@iconscout/react-unicons/icons/uil-facebook";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";
import { Helmet } from "react-helmet";

/* IMPORT API */
import { contactUs } from "../../service/api";
import { useState } from "react";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("Please provide your first name"),
  lastName: yup.string().required("Please provide your last name"),
  email: yup.string().email().required("Please provide your emmail address"),
  phone: yup.string().required("Please provide your phone number"),
  message: yup.string().required("Please provide the reason"),
});

const ContactUs = () => {
  const [formSubmit, setFormSubmit] = useState(false);

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormSubmit(true);
      const body = {
        name: values?.firstName + " " + values?.lastName,
        phone: values?.phone,
        email: values?.email,
        message: values?.message,
      };
      contactUs(body)
        .then((res) => {
          if (res?.status) {
            console.log(res?.message);
            toast.success(res?.message);
            formik.resetForm();
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setFormSubmit(false);
        });
    },
    // onSubmit: (values, { resetForm }) => {
    //   toast.success("Thank you for reaching out to me. I will get back to you shortly.")
    //   console.log('FORM VALUES', values);
    //   resetForm();
    // }
  });

  return (
    <section className="contact-us">
      <Helmet>
        <title>Contact Dr Vivek Kakkad | IVF Superspecialist</title>
      </Helmet>

      <Toaster />

      {/* BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h1>Get in Touch with the IVF Superspecialist</h1>
              <h5>Contact me to book an appointment</h5>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CONTACT MAP */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="address-map"
      >
        <div className="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.636848164385!2d72.55904697596466!3d23.03710241578465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85cff4e604fd%3A0x16078192a84f1b16!2sBirla%20Fertility%20%26%20IVF%20-%20Ahmedabad!5e0!3m2!1sen!2sin!4v1704780222568!5m2!1sen!2sin"
            title="Clinic-Address"
            className="google-map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </motion.div>

      {/* FORM */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="message-form"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-sm-12">
              <h2>Get in touch with me</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        First Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.firstName && formik.touched.firstName
                            ? "validation-bg"
                            : ""
                        }`}
                        placeholder="First Name"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.firstName && formik.touched.firstName ? (
                        <small className="validation-text">
                          Please provide your First Name
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Last Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.lastName && formik.touched.lastName
                            ? "validation-bg"
                            : ""
                        }`}
                        placeholder="Last Name"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.firstName && formik.touched.firstName ? (
                        <small className="validation-text">
                          Please provide your Last Name
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span>*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          formik.errors.email && formik.touched.email
                            ? "validation-bg"
                            : ""
                        }`}
                        placeholder="Email Address"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <small className="validation-text">
                          Please provide your Email
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Phone <span>*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.phone && formik.touched.phone
                            ? "validation-bg"
                            : ""
                        }`}
                        placeholder="Phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.phone && formik.touched.phone ? (
                        <small className="validation-text">
                          Please provide your Phone
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Message <span>*</span>
                      </label>
                      <textarea
                        rows={5}
                        className={`textarea-control ${
                          formik.errors.email && formik.touched.email
                            ? "validation-bg"
                            : ""
                        }`}
                        placeholder="Message"
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.message && formik.touched.message ? (
                        <small className="validation-text">
                          Please provide the reason
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 col-sm-12">
                    {formSubmit ? (
                      <Button className="submit-btn">PLEASE WAIT</Button>
                    ) : (
                      <Button className="submit-btn" type="submit">
                        SEND MESSAGE
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="sidebar">
                <h2>Contact Details</h2>
                <div className="details-box">
                  <h5>WORKING HOURS</h5>
                  <div className="working-days">
                    <p className="day">Monday - Wednesday</p>
                    <p className="time">10:00 AM - 6:00 PM</p>
                  </div>
                  <div className="working-days">
                    <p className="day">Thursday</p>
                    <p className="time">Closed</p>
                  </div>
                  <div className="working-days">
                    <p className="day">Friday - Sunday</p>
                    <p className="time">10:00 AM - 6:00 PM</p>
                  </div>

                  <h5 className="contact-details-heading">FOLLOW ME ON</h5>
                  <div className="social-media-icons">
                    <a
                      href="https://www.facebook.com/profile.php?id=100092650021285"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon color="#FFF" size="20" />
                    </a>
                    <a
                      href="https://www.instagram.com/gujfertilitydoc/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon color="#FFF" size="20" />
                    </a>
                    <a
                      href="https://twitter.com/drkakkadvivek1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon color="#FFF" size="20" />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/vivek-kakkad-b1108a90/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedinIcon color="#FFF" size="20" />
                    </a>
                  </div>

                  <h5 className="contact-details-heading">CONTACT DETAILS</h5>
                  <div className="cta">
                    <a href="tel:+919292283962">
                      <img src={TelephoneIcon} alt="Contact Dr. Vivek Kakkad" />
                      +91 9898283962
                    </a>
                  </div>
                  <div className="cta">
                    <a href="mailto:info@drkakkadvivek.com">
                      <img src={EmailIcon} alt="Email Dr. Vivek Kakkad" />
                      info@drkakkadvivek.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default ContactUs;
