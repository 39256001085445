/* EXTERNAL IMPORTS */
import TelephoneIcon from "../../assets/icons/telephone.png";
import EmailIcon from "../../assets/icons/email.png";
import FacebookIcon from "@iconscout/react-unicons/icons/uil-facebook";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";

const Footer = () => {
  return (
    <footer className="footer">
      <div
        className={`map-section ${
          window.location.pathname === "/contact-us" ? "d-none" : "d-block"
        }`}
      >
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-3 col-sm-12">
              <div className="details-box">
                <h5>WORKING HOURS</h5>
                <div className="working-days">
                  <p className="day">Mon-Wed</p>
                  <p className="time">10:00 AM - 6:00 PM</p>
                </div>
                <div className="working-days">
                  <p className="day">Thursday</p>
                  <p className="time">Closed</p>
                </div>
                <div className="working-days">
                  <p className="day">Fri-Sun</p>
                  <p className="time">10:00 AM - 6:00 PM</p>
                </div>

                <h5 className="contact-details-heading">FOLLOW ME ON</h5>
                <div className="social-media-icons">
                  <a
                    href="https://www.facebook.com/profile.php?id=100092650021285"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon color="#FFF" size="20" />
                  </a>
                  <a
                    href="https://www.instagram.com/gujfertilitydoc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon color="#FFF" size="20" />
                  </a>
                  <a
                    href="https://twitter.com/drkakkadvivek1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon color="#FFF" size="20" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/vivek-kakkad-b1108a90/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedinIcon color="#FFF" size="20" />
                  </a>
                </div>

                <h5 className="contact-details-heading">CONTACT DETAILS</h5>
                <div className="cta">
                  <a href="tel:+919292283962">
                    <img src={TelephoneIcon} alt="Contact Dr. Vivek Kakkad" />
                    +91 9898283962
                  </a>
                </div>
                <div className="cta">
                  <a href="mailto:info@drkakkadvivek.com">
                    <img src={EmailIcon} alt="Email Dr. Vivek Kakkad" />
                    info@drkakkadvivek.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* GOOGLE MAP */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.636848164385!2d72.55904697596466!3d23.03710241578465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85cff4e604fd%3A0x16078192a84f1b16!2sBirla%20Fertility%20%26%20IVF%20-%20Ahmedabad!5e0!3m2!1sen!2sin!4v1704780222568!5m2!1sen!2sin"
          title="Clinic-Address"
          className="google-map"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="copyright-section">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between copy-links">
            <h5>Dr. Vivek Kakkad © 2024. All rights reserved.</h5>
            <div className="page-links">
              <h5>
                Powered by{" "}
                <a
                  href="https://www.roundtechsquare.com"
                  style={{ color: "#FFF" }}
                >
                  RoundTechSquare
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
