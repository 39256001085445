import { Button, Card } from "react-bootstrap";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const NewMaleAndFemaleInfertility = () => {
  return (
    <section className="new-services-page">
      <Helmet>
        <title>Male and Female Fertility services and care | IVF Superspecialist</title>
      </Helmet>

      {/* BANNER TEXT */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="banner-text"
      >
        <div className="container">
          <div className="row justify-content-between gx-5">
            <div className="col-xl-5 col-sm-12">
              <h5>MALE AND FEMALE FERTILITY</h5>
              <h1>Male and Female Fertility services and care.</h1>
              <p>
                Our clinic provides a range of services to support our patients'
                family-building journey. We offer comprehensive consultations,
                assessments, and personalized conception care, including
                third-party treatment options, to ensure our patients have the
                resources they need to navigate the process efficiently and
                effectively.
              </p>
              <Button onClick={() =>
                window.Calendly.initPopupWidget({
                  url: "https://calendly.com/dr-kakkadvivek/appointment",
                })
              } className="consultation-btn">
                Schedule consultation
              </Button>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="row h-100">
                <div className="col-xl-6 col-6">
                  <img
                    src={require("../../../assets/services-banner/fertility-banner-01.jpeg")}
                    className="service-banner"
                    alt="Male and Female Fertility services and care."
                  />
                </div>
                <div className="col-xl-6 col-6">
                  <img
                    src={require("../../../assets/services-banner/fertility-banner-02.jpeg")}
                    className="service-banner"
                    alt="Male and Female Fertility services and care."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PATHS TO CONCEPTION */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="paths-to-conception"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-sm-12">
              <h2>Fertility and Family Building</h2>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-xl-10 col-sm-12">
              <div className="paths-box">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-5 col-sm-12">
                    <img
                      src={require("../../../assets/services-banner/male-fertility.webp")}
                      alt="Male Fertility"
                    />
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <h3>Male Fertility</h3>
                    <p>
                      As a healthcare provider specializing in male infertility,
                      I understand the unique challenges that men face when
                      struggling to conceive a child. My commitment to providing
                      individualized care and support throughout the male
                      infertility journey highlights your compassion for men's
                      physical and emotional well-being. I believe that every
                      man deserves personalized care, which may involve
                      diagnostic testing, lifestyle modifications, or assisted
                      reproductive technologies, to achieve their dream of
                      fatherhood. By offering comprehensive care and guidance, I
                      empower men to make informed decisions and navigate the
                      complexities of infertility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-sm-12">
              <div className="paths-box bg-white">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-6 col-sm-12">
                    <h3>Female Infertility</h3>
                    <p>
                      My dedication to helping women of all ages and at any
                      stage of their journey is admirable. My approach to
                      actively listening and clarifying your patients' concerns
                      and needs is integral to achieving positive outcomes.
                      Through personalized care and attention, I empower my
                      patients to navigate their health challenges and improve
                      their overall well-being.
                    </p>
                    <p>
                      As a healthcare provider specializing in female
                      infertility, I recognize the emotional and physical toll
                      that fertility struggles can have on women. I believe that
                      every woman deserves personalized care and support on her
                      journey to conception, whether it be through preconception
                      counseling, assisted reproductive technologies, or
                      managing complications.
                    </p>
                  </div>
                  <div className="col-xl-5 col-sm-12">
                    <img
                      src={require("../../../assets/services-banner/female-fertility.webp")}
                      className="small-height"
                      alt="Timed Intercourse"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* SCHEDULE CONSULTATION */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="schedule-consultation"
      >
        <div className="container">
          <div className="row align-items-center gx-5">
            <div className="col-xl-3 col-sm-12">
              <h2>Get in Touch</h2>
              <h5>The family you've dreamed of, now within reach.</h5>
            </div>
            <div className="col-xl-9 col-sm-12">
              <div className="row">
                <div className="col-xl-4 col-sm-12">
                  <Card className="appointment-card">
                    <Card.Body className="appointment-cardbody">
                      <h4>Inclusive</h4>
                      <p>
                        We focus on inclusive care for all people of all races,
                        gender identities, and sexual orientations in welcoming,
                        convenient locations.
                      </p>
                    </Card.Body>
                    <Card.Footer className="appointment-cardfooter">
                      <Button
                        className="appointment-btn"
                        onClick={() =>
                          window.Calendly.initPopupWidget({
                            url: "https://calendly.com/dr-kakkadvivek/appointment",
                          })
                        }
                      >
                        Schedule appointment
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <Card className="appointment-card">
                    <Card.Body className="appointment-cardbody">
                      <h4>Healthcare Guidance</h4>
                      <p>
                        We are dedicated to helping you navigate these complex
                        processes with compassion and expertise, so you can
                        achieve your dream of building a family.
                      </p>
                    </Card.Body>
                    <Card.Footer className="appointment-cardfooter">
                      <Button
                        className="appointment-btn"
                        onClick={() =>
                          window.Calendly.initPopupWidget({
                            url: "https://calendly.com/dr-kakkadvivek/appointment",
                          })
                        }
                      >
                        Schedule appointment
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <Card className="appointment-card">
                    <Card.Body className="appointment-cardbody">
                      <h4>Accessible</h4>
                      <p>
                        At our clinic, we are committed to providing affordable
                        options without compromising the quality of care. Our
                        pricing is competitive and transparent.
                      </p>
                    </Card.Body>
                    <Card.Footer className="appointment-cardfooter">
                      <Button
                        className="appointment-btn"
                        onClick={() =>
                          window.Calendly.initPopupWidget({
                            url: "https://calendly.com/dr-kakkadvivek/appointment",
                          })
                        }
                      >
                        Schedule appointment
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* STEPS TO FOLLOW */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="holistic-care"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h2>Points to remember for successful pregnancy</h2>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/healthy-diet.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Eat a Healthy Diet</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/exercise.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Exercise Regularly</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/sleep.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Get Enough Sleep</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/doctor-bag.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Follow Medical Advice</h5>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="tips-box">
                <div className="steps-icon-box">
                  <img
                    src={require("../../../assets/icons/stress.png")}
                    alt="Steps Icon"
                  />
                </div>
                <h5>Manage Stress</h5>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default NewMaleAndFemaleInfertility;
