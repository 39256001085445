import { Link } from "react-router-dom";

/* IMAGES */
import SignOfMaleFertility from "../assets/blogs/signs-of-male-infertility.webp";
import IVFProcedure from "../assets/blogs/is-ivf-painful.webp";
import SuccessfulEmbryoTransfer from "../assets/blogs/sign-of-successful-embryo-transfer.webp";
import SecondaryInfertilty from "../assets/blogs/secondary-infertility.webp";

const BlogSidebar = () => {
    return (
      <div className="col-xl-4 col-sm-12">
        <div className="blog-detail-sidebar">
          {/* <div className="contact-form">
            <h4>Get in touch</h4>
            <form>
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  placeholder="Phone"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Message</label>
                <textarea
                  className="textarea-control"
                  name="message"
                  placeholder="Message"
                />
              </div>
              <Button className="submit-btn">SEND MESSAGE</Button>
            </form>
          </div> */}

          {/* RECENTY BLOGS */}
          <div className="blogs-list">
            <h4>Recent Blogs</h4>
            <Link
              to="/symptoms-and-signs-of-male-infertility"
              style={{ textDecoration: "none" }}
            >
              <div className="list">
                <div className="row">
                  <div className="col-xl-4 col-4">
                    <img
                      src={SignOfMaleFertility}
                      alt="Symptoms and Signs of Male Fertility"
                    />
                  </div>
                  <div className="col-xl-8 col-8">
                    <h5>Symptoms and Signs of Male Infertility</h5>
                    <p>
                      Male Infertility is more common than people think.
                      Approximately 10 percent of couples
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              to="/is-ivf-procedure-painful"
              style={{ textDecoration: "none" }}
            >
              <div className="list">
                <div className="row">
                  <div className="col-xl-4 col-4">
                    <img src={IVFProcedure} alt="Is IVF Procedure Painful" />
                  </div>
                  <div className="col-xl-8 col-8">
                    <h5>Is IVF Procedure Painful?</h5>
                    <p>
                      In vitro fertilization (IVF) is one of the most well-known
                      and widely used fertility procedures. It is a...
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              to="/successful-embryo-transfer"
              style={{ textDecoration: "none" }}
            >
              <div className="list">
                <div className="row">
                  <div className="col-xl-4 col-4">
                    <img
                      src={SuccessfulEmbryoTransfer}
                      alt="Sign of successful Embryo Transfer"
                    />
                  </div>
                  <div className="col-xl-8 col-8">
                    <h5>Sign of successful Embryo Transfer</h5>
                    <p>
                      Embryo transfer is an important and final step of in vitro
                      fertilization (IVF) treatment....
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              to="/secondary-infertility"
              style={{ textDecoration: "none" }}
            >
              <div className="list">
                <div className="row">
                  <div className="col-xl-4 col-4">
                    <img
                      src={SecondaryInfertilty}
                      alt="Secondary Infertility (Causes, Treatment): All You Want to Know"
                    />
                  </div>
                  <div className="col-xl-8 col-8">
                    <h5>
                      Secondary Infertility (Causes, Treatment): All You Want to
                      Know
                    </h5>
                    <p>
                      Find out about the secondary infertility causes, the
                      difference between primary and secondary......
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
};

export default BlogSidebar;