import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

/* COMPONENT IMPORTS */
import BlogBox from '../../components/blog-box';

/* IMAGE IMPORTS */
import SymptomsMaleFertility from "../../assets/blogs/signs-of-male-infertility.webp";
import FrozeenEmbryoTransfer from "../../assets/blogs/frozen-embryo-transfer-procedure.webp";
import IVFProcedure from "../../assets/blogs/is-ivf-painful.webp";
import EmbryoTransfer from "../../assets/blogs/sign-of-successful-embryo-transfer.webp";
import SecondaryInfertility from "../../assets/blogs/secondary-infertility.webp";
import OvulationSigns from "../../assets/blogs/ovulation-signs.webp";
import FertilityPreservation from "../../assets/blogs/fertility-preservation.webp";
import IVFProcess from "../../assets/blogs/ivf-process.webp";
import { Helmet } from 'react-helmet';

const Blogs = () => {
  return (
    <section className="our-blogs">
      <Helmet>
        <title>Publications and Blogs | IVF Superspecialist</title>
      </Helmet>

      {/* BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h1>Latest Blogs</h1>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="blogs-list"
      >
        <div className="container">
          <div className="row align-items-center">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-6 col-sm-12"
            >
              <div className="featured-blogs">
                <h5>Featured Blogs</h5>
                <Link
                  to="/symptoms-and-signs-of-male-infertility"
                  style={{ textDecoration: "none" }}
                >
                  <div className="blog-latest-box">
                    <div className="row align-items-center">
                      <div className="col-xl-4 col-sm-12">
                        <img
                          src={SymptomsMaleFertility}
                          alt="Symptoms and Signs of Male Infertility"
                        />
                      </div>
                      <div className="col-xl-8 col-sm-12">
                        <h5>Symptoms and Signs of Male Infertility</h5>
                        <p>
                          Male Infertility is more common than people think.
                          Approximately 10 percent of couples...
                        </p>
                        <Link
                          href="/symptoms-and-signs-of-male-infertility"
                          className="learn-more-link"
                        >
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/is-ivf-procedure-painful"
                  style={{ textDecoration: "none" }}
                >
                  <div className="blog-latest-box">
                    <div className="row align-items-center">
                      <div className="col-xl-4 col-sm-12">
                        <img
                          src={IVFProcedure}
                          alt="Is IVF Procedure Painful?"
                        />
                      </div>
                      <div className="col-xl-8 col-sm-12">
                        <h5>Is IVF Procedure Painful?</h5>
                        <p>
                          In vitro fertilization (IVF) is one of the most
                          well-known and widely used fertility procedures. It
                          is...
                        </p>
                        <Link
                          href="/is-ivf-procedure-painful"
                          className="learn-more-link"
                        >
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-6 col-sm-12"
            >
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <BlogBox
                    blogImg={EmbryoTransfer}
                    blogTitle="Signs Of Successful Embryo Transfer"
                    blogDesc="Embryo transfer is an important and final step of in vitro fertilization (IVF) treatment..."
                    blogLink="/successful-embryo-transfer"
                  />
                </div>
                <div className="col-xl-6 col-sm-12">
                  <BlogBox
                    blogImg={SecondaryInfertility}
                    blogTitle="Secondary Infertility"
                    blogDesc="Find out about the secondary infertility causes, the difference between primary and secondary..."
                    blogLink="/secondary-infertility"
                  />
                </div>
              </div>
            </motion.div>
          </div>
          <div className="row gy-2 mt-3">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-3 col-sm-12"
            >
              <BlogBox
                blogImg={FrozeenEmbryoTransfer}
                blogTitle="Frozen Embryo Transfer Procedure: All You Want to Know"
                blogDesc="Learn more about the frozen embryo transfer procedure. Find out what happens after..."
                blogLink="/frozen-embryo-transfer-procedure"
              />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-3 col-sm-12"
            >
              <BlogBox
                blogImg={OvulationSigns}
                blogTitle="Common Ovulation Signs to Determine When You’re Most Fertile"
                blogDesc="There are some common signs of ovulation that you can use to determine when you’re most..."
                blogLink="/common-ovulation-signs"
              />{" "}
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-3 col-sm-12"
            >
              <BlogBox
                blogImg={FertilityPreservation}
                blogTitle="Fertility preservation in India and the challenges"
                blogDesc="India-specific fertility-preservation challenges and their resolution cancer continues to be a..."
                blogLink="/fertility-preservation-in-india"
              />{" "}
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-3 col-sm-12"
            >
              <BlogBox
                blogImg={IVFProcess}
                blogTitle="Conceiving With In-Vitro Fertilization (IVF): What to Expect"
                blogDesc="Starting with an IVF treatment procedure can bring about a variety of emotions. The journey..."
                blogLink="/conceiving-with-ivf"
              />{" "}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Blogs;
